import { Grid, Typography, Box, Snackbar, Alert } from '@mui/material';
import { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

type Props = {
	clientId: string;
};

const ClientDeployment = ({ clientId }: Props) => {
	const [snackOpen, setSnackOpen] = useState(false);

	const onClickHandler = () => {
		navigator.clipboard.writeText(
			`powershell "[Net.ServicePointManager]::SecurityProtocol = [Net.SecurityProtocolType]::Tls12;(New-Object Net.WebClient).DownloadFile('https://securemydesktop.com/downloads/SecureMyDesktop.exe',\\"$env:temp/SecureMyDesktop.exe\\");"%TEMP%\\SecureMyDesktop.exe /exenoui /qn CLIENTID="${clientId}"`
		);
		setSnackOpen(true);
	};

	const handleSnackClose = (
		event?: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackOpen(false);
	};

	return (
		<Grid
			container
			sx={{
				border: '1px solid',
				borderColor: '#e5e9de',
				borderRadius: '4px',
				backgroundColor: '#e5e9de',
				mt: 2,
				boxShadow: 2,
			}}
		>
			<Snackbar
				open={snackOpen}
				autoHideDuration={6000}
				onClose={handleSnackClose}
			>
				<Alert
					onClose={handleSnackClose}
					severity='success'
					sx={{ width: '100%', backgroundColor: '#d8dbd1' }}
				>
					Copied!
				</Alert>
			</Snackbar>
			<Grid item xs={12} m={1}>
				<Typography variant='h5'>CLI Agent Deployment</Typography>
			</Grid>
			<Grid item xs={12} m={1}>
				<Typography variant='body1'>
					Through an{' '}
					<Box component='span' sx={{ fontWeight: '600' }}>
						administrative command prompt
					</Box>{' '}
					session, use the following code to install the SecureMyDesktop agent.
					The installation process takes ~30 seconds to complete and then report
					to the devices board.
					<br></br>
					<br></br> Should you have any issues with the installation process
					please contact{' '}
					<a href='mailto:support@securemydesktop.com'>
						support@securemydesktop.com
					</a>
				</Typography>
			</Grid>
			<Grid item xs={12} onClick={onClickHandler} sx={{ cursor: 'pointer' }}>
				<SyntaxHighlighter
					language='javascript'
					style={docco}
					wrapLines={true}
					wrapLongLines={true}
					customStyle={{
						borderRadius: '4px',
						marginLeft: 10,
						marginRight: 10,
					}}
				>{`powershell "[Net.ServicePointManager]::SecurityProtocol = [Net.SecurityProtocolType]::Tls12;(New-Object Net.WebClient).DownloadFile('https://anetworks.com/downloads/SecureMyDesktop.exe',\\"$env:temp/SecureMyDesktop.exe\\");"%TEMP%\\SecureMyDesktop.exe /exenoui /qn CLIENTID="${clientId}"`}</SyntaxHighlighter>
			</Grid>
		</Grid>
	);
};

export default ClientDeployment;
